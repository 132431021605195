/* eslint-disable react/no-unescaped-entities */

import { Link } from 'gatsby'
import React from 'react'
import { Email } from '../../components/shared/email'
import LegalLayout from '../../components/shared/legal-layout'
import { DEVELOPER_RU, HELP_EMAIL } from '../../constants'

const PolicyPage = () => {
    return (
        <LegalLayout>
            <article>
                <h1>Политика конфиденциальности</h1>
                Политика конфиденциальности персональной информации (далее —
                Политика) действует в отношении всей информации, которую
                {DEVELOPER_RU} и/или его аффилированные лица, (далее —
                Правообладатель), могут получить о Пользователе (далее -
                Пользователь, Вы) во время использования им любого из сервисов,
                служб, программ, сайтов, продуктов или услуг Правообладателя
                (далее — Сервисы, Сервисы Правообладателя) и в ходе исполнения
                Правообладателем любых соглашений и договоров с Пользователем.
                Согласие Пользователя с Политикой, выраженное им в рамках
                отношений с одним из перечисленных лиц, распространяется на все
                остальные перечисленные лица.
                <br />
                <br />
                Использование Сервисов Правообладателя означает безоговорочное
                согласие Пользователя с настоящей Политикой и указанными в ней
                условиями обработки его персональной информации; в случае
                несогласия с этими условиями Пользователь должен воздержаться от
                использования Сервисов.
                <ol>
                    <li>
                        <h2>
                            Персональная информация Пользователей, которую
                            обрабатывает Правообладатель
                        </h2>
                        <ol>
                            <li>
                                Персональная информация Пользователей, которую
                                обрабатывает Правообладатель
                                <ol>
                                    <li>
                                        В рамках настоящей Политики под
                                        «персональной информацией Пользователя»
                                        понимаются:
                                        <ol>
                                            <li>
                                                Персональная информация, которую
                                                Пользователь предоставляет о
                                                себе самостоятельно при
                                                регистрации (создании учётной
                                                записи) или в процессе
                                                использования Сервисов, включая
                                                персональные данные
                                                Пользователя. Обязательная для
                                                предоставления Сервисов
                                                информация помечена специальным
                                                образом. Иная информация
                                                предоставляется Пользователем на
                                                его усмотрение.
                                            </li>
                                            <li>
                                                Данные, которые автоматически
                                                передаются Сервисам
                                                Правообладателя в процессе их
                                                использования с помощью
                                                установленного на устройстве
                                                Пользователя программного
                                                обеспечения, в том числе
                                                IP-адрес, данные файлов cookie,
                                                информация о браузере
                                                Пользователя (или иной
                                                программе, с помощью которой
                                                осуществляется доступ к
                                                Сервисам), технические
                                                характеристики оборудования и
                                                программного обеспечения,
                                                используемых Пользователем, дата
                                                и время доступа к Сервисам,
                                                адреса запрашиваемых страниц и
                                                иная подобная информация.
                                            </li>
                                            <li>
                                                Иная информация о Пользователе,
                                                обработка которой предусмотрена
                                                условиями использования
                                                отдельных Сервисов
                                                Правообладателя.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Настоящая Политика применима только к
                                информации, обрабатываемой в ходе использования
                                Сервисов Правообладателя. Правообладатель не
                                контролирует и не несет ответственность за
                                обработку информации сервисами третьих лиц, на
                                которые Пользователь может перейти по ссылкам,
                                доступным в Сервисах Правообладателя.
                            </li>
                            <li>
                                Правообладатель не проверяет достоверность
                                персональной информации, предоставляемой
                                Пользователем, и не имеет возможности оценивать
                                его дееспособность. Однако Правообладатель
                                исходит из того, что пользователь предоставляет
                                достоверную и достаточную персональную
                                информацию и поддерживает эту информацию в
                                актуальном состоянии.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>
                            Цели обработки персональной информации Пользователей
                        </h2>
                        <ol>
                            <li>
                                Правообладатель собирает и хранит только ту
                                персональную информацию, которая необходима для
                                предоставления Сервисов или исполнения
                                соглашений и договоров с Пользователем, за
                                исключением случаев, когда законодательством
                                предусмотрено обязательное хранение персональной
                                информации в течение определенного законом
                                срока.
                            </li>
                            <li>
                                Цели обработки персональной информации
                                Пользователей
                                <ol>
                                    <li>
                                        Правообладатель собирает и хранит только
                                        ту персональную информацию, которая
                                        необходима для предоставления Сервисов
                                        или исполнения соглашений и договоров с
                                        Пользователем, за исключением случаев,
                                        когда законодательством предусмотрено
                                        обязательное хранение персональной
                                        информации в течение определенного
                                        законом срока.
                                    </li>
                                    <li>
                                        Персональную информацию Пользователя
                                        Правообладатель обрабатывает в следующих
                                        целях:
                                        <ol>
                                            <li>
                                                Идентификация стороны в рамках
                                                Сервисов, соглашений и договоров
                                                с Правообладателем;
                                            </li>
                                            <li>
                                                Предоставление Пользователю
                                                персонализированных Сервисов и
                                                исполнение соглашений и
                                                договоров;
                                            </li>
                                            <li>
                                                Связь с Пользователем, в том
                                                числе направление уведомлений,
                                                запросов и информации,
                                                касающихся использования
                                                Сервисов, исполнения соглашений
                                                и договоров, а также обработка
                                                запросов и заявок от
                                                Пользователя;
                                            </li>
                                            <li>
                                                Улучшение качества Сервисов,
                                                удобства их использования,
                                                разработка новых Сервисов;
                                            </li>
                                            <li>
                                                Таргетирование рекламных
                                                материалов;
                                            </li>
                                            <li>
                                                Проведение статистических и иных
                                                исследований на основе
                                                обезличенных данных.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>
                            Условия обработки персональной информации
                            Пользователей и её передачи третьим лицам
                        </h2>
                        <ol>
                            <li>
                                Правообладатель хранит персональную информацию
                                Пользователей в соответствии с внутренними
                                регламентами конкретных сервисов.
                            </li>
                            <li>
                                В отношении персональной информации Пользователя
                                сохраняется ее конфиденциальность, кроме случаев
                                добровольного предоставления Пользователем
                                информации о себе для общего доступа
                                неограниченному кругу лиц. При использовании
                                отдельных Сервисов, Пользователь соглашается с
                                тем, что определённая часть его персональной
                                информации становится общедоступной.
                            </li>
                            <li>
                                Правообладатель вправе передать персональную
                                информацию Пользователя третьим лицам в
                                следующих случаях:
                                <ol>
                                    <li>
                                        Пользователь выразил согласие на такие
                                        действия;
                                    </li>
                                    <li>
                                        Передача необходима для использования
                                        Пользователем определенного Сервиса либо
                                        для исполнения определенного соглашения
                                        или договора с Пользователем;
                                    </li>
                                    <li>
                                        Передача предусмотрена белорусским или
                                        иным применимым законодательством в
                                        рамках установленной законодательством
                                        процедуры;
                                    </li>
                                    <li>
                                        Такая передача происходит в рамках
                                        продажи или иной передачи бизнеса
                                        (полностью или в части), при этом к
                                        приобретателю переходят все
                                        обязательства по соблюдению условий
                                        настоящей Политики применительно к
                                        полученной им персональной информации;
                                    </li>
                                    <li>
                                        В целях обеспечения возможности защиты
                                        прав и законных интересов
                                        Правообладателя или третьих лиц в
                                        случаях, когда Пользователь нарушает
                                        {'  '}
                                        <Link
                                            to="/ru/legal/terms"
                                            title="Пользовательское соглашение"
                                        />
                                        {'  '}
                                        сервисов Правообладателя, которое
                                        включено в настоящую Политику в виде
                                        ссылки, настоящую Политику, либо
                                        документы, содержащие условия
                                        использования конкретных Сервисов.
                                    </li>
                                    <li>
                                        В результате обработки персональной
                                        информации Пользователя путем ее
                                        обезличивания получены обезличенные
                                        статистические данные, которые
                                        передаются третьему лицу для проведения
                                        исследований, выполнения работ или
                                        оказания услуг по поручению
                                        Правообладателя.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                При обработке персональных данных Пользователей
                                Правообладатель руководствуется действующим
                                законодательством РБ.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>
                            Пиксели и другие подобные им технологии сбора данных
                        </h2>
                        <ol>
                            <li>
                                Правообладатель использует и может разрешать
                                другим использовать пиксели и подобные им
                                технологии (например, веб-маяки, cookie), чтобы
                                распознавать вас и ваши устройства.
                                Правообладатель использует пиксели для
                                идентификации пользователя, запоминания его
                                предпочтений и настроек, анализа трафика и
                                тенденций, реализации и оценки эффективности
                                рекламных кампаний, поддержки функций социальных
                                сетей.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>
                            Меры, применяемые для защиты персональной информации
                            Пользователя
                        </h2>
                        <ol>
                            <li>
                                Правообладатель принимает необходимые и
                                достаточные организационные и технические меры
                                для защиты персональной информации Пользователя
                                от неправомерного или случайного доступа,
                                уничтожения, изменения, блокирования,
                                копирования, распространения, а также от иных
                                неправомерных действий с ней третьих лиц.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>
                            Изменение Политики конфиденциальности. Применимое
                            законодательство
                        </h2>
                        <ol>
                            <li>
                                Правообладатель имеет право вносить изменения в
                                настоящую Политику конфиденциальности. Новая
                                редакция Политики вступает в силу с момента ее
                                размещения, если иное не предусмотрено новой
                                редакцией Политики. Действующая редакция
                                постоянно доступна на странице по адресу:{'  '}
                                <Link
                                    to="/ru/legal/terms"
                                    title="Пользовательское соглашение"
                                />
                                .
                            </li>
                            <li>
                                К настоящей Политике и отношениям между
                                Пользователем и Правообладателем, возникающим в
                                связи с применением Политики конфиденциальности,
                                подлежит применению право Республики Беларусь
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Обратная связь. Вопросы и предложения</h2>
                        <ol>
                            <li>
                                Все предложения или вопросы по поводу настоящей
                                Политики Пользователь вправе направлять в Службу
                                поддержки пользователей Правообладателя{'  '}
                                <Email email={HELP_EMAIL} />
                            </li>
                        </ol>
                    </li>
                </ol>
            </article>
        </LegalLayout>
    )
}

export default PolicyPage
